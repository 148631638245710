import everestprojectImage from '../../../assets/cat_selected.png';
import everestSummaryImage from '../../../assets/everest-mobile.png';
import everest2 from '../../../assets/category_added.png';
import sunday_chat from '../../../assets/sunday_image.png';
import insight_dashboard from '../../../assets/insight_dashboard.png';
import sunday_mobile_shot from '../../../assets/sunday_mobile_shot.png'
import insight_mobile_shot from '../../../assets/insight_mobile_shot.png';
import sunday_architecture from '../../../assets/sunday_architecture.png';
import everest_architecture from '../../../assets/everest_architecture.png'
import attrition_architecture from '../../../assets/attrition_architecture.png'


const PageTransition = {
  initial: { y: '100%' },
  animate: { y: 0 },
  exit: { y: '100%' },
  transition: { duration: 2 },
};

const projectData = [
  {
    id: 1,
    urlExtension: 'sunday',
    title: 'SUNDAY',
    projectName: 'Project Sunday',
    projectDescription: 'A chat app embed with a NLP transformer model',
    projectImage: sunday_chat,
    projectYear: 2023,
    projectGitHub: 'https://github.com/coderacheal/Sunday',
    projectWebsite: 'https://github.com/coderacheal/Sunday',
    miniIntro: 'No more drunk texting! Sunday is a chat app embed with a NLP transformer model that predicts the sentiment(+ probability) of your text before you shoot yourself in the foot.',
    projectSummaryOne: 'Sunday is an innovative chat application powered by a natural language processing (NLP) transformer model from Hugging Face. The app analyzes and predicts the sentiment of messages—Positive, Negative, Neutral, Litigious, or Uncertainty—along with the corresponding probability, allowing users to review sentiment feedback before sending their text',
    projectSummaryTwo: 'Built with FastAPI and a React front end, the app is fully dockerized for streamlined deployment. Designed as an educational tool, Sunday offers a unique insight into how language is perceived, making it an ideal solution for academic and research purposes.',
    nextProjectName: 'Project Insight',
    backgroundColor: 'green',
    viewProject: 'View Next Project',
    route: '/insight',
    techStack: ['NLP', 'FastAPI', 'React', 'Docker', 'Huggingface'],
    color: 'black',
    summaryImage: sunday_mobile_shot,
    projectCardImage: sunday_chat,
    summaryImageHeight: '50vh',
    summaryImageWidth: '30vw',
    role: 'Data Scientist',
    viewProjectbackgroundColor: 'rgba(159, 233, 180, 0.5)',
    viewProjectTextColor: '',
    architecture: sunday_architecture,
  },
  {
    id: 2,
    urlExtension: 'insight',
    title: 'INSIGHT',
    projectName: 'Project Insight',
    projectDescription: 'An application that predicts customer attrition',
    projectImage: insight_dashboard,
    projectYear: 2024,
    projectGitHub: 'https://github.com/coderacheal/Attrition-Insight',
    projectWebsite: 'https://attrition-insight.onrender.com/',
    miniIntro: 'Insight is a data application that allows users to interact with a machine learning model by predicting employee attrition based on certain demographics and see visualizations on the data.',
    projectSummaryOne: 'Insight is a predictive analytics application focused on employee attrition. Leveraging a machine learning classification model, it enables users to forecast attrition based on key demographic data.',
    projectSummaryTwo: 'I developed the front-end using Streamlit, providing an interactive interface for input and data visualization. The project was deployed on Render, ensuring seamless access and user engagement. This solution is designed to offer actionable insights for workforce management and retention strategies',
    builtFor: 'Attrition Prediction',
    nextProjectName: 'Project Everest',
    backgroundColor: 'rgb(25, 13, 60)',
    viewProject: 'View Next Project',
    route: '/everest',
    techStack: ['Python', 'Streamlit', 'Scikit-learn', 'Render'],
    color: 'black',
    summaryImage: insight_mobile_shot,
    projectCardImage: insight_dashboard,
    summaryImageHeight: '65vh',
    summaryImageWidth: '30vw',
    role: 'Data Scientist',
    viewProjectbackgroundColor:  'rgb(168, 203, 244)',
    viewProjectTextColor: '',
    architecture: attrition_architecture,
  },
  {
    id: 3,
    urlExtension: 'everest',
    title: 'EVEREST',
    projectName: 'Project Everest',
    projectDescription: 'An app for managing your finances',
    projectImage: everestprojectImage,
    projectYear: 2023,
    projectGitHub: 'https://github.com/coderacheal/everest',
    projectWebsite: 'https://everest-app-xhv2.onrender.com',
    miniIntro: 'Want to budget in a fun and interactive app and stay on top of the dollar? Everest allows you to track your expenses by setting limit for various categories',
    projectSummaryOne: 'EEverest is a secure, user-friendly budgeting application designed to enhance the financial management experience. Featuring a clean, engaging interface, the app allows users to categorize and track expenses with ease.',
    projectSummaryTwo: 'Integrated with user authentication through Devise, Everest ensures data security while helping users stay in control of their finances. Built using Rails and PostgreSQL, the app provides a reliable and efficient platform for personal budgeting.',
    builtFor: 'For all the personal finance lovers',
    nextProjectName: 'Project Sunday',
    backgroundColor: 'purple',
    viewProject: 'View Next Project',
    route: '/sunday',
    techStack: ['Ruby on Rails', 'PostgreSQL', 'Devise', 'React'],
    color: 'black',
    summaryImage: everestSummaryImage,
    projectCardImage: everest2,
    summaryImageHeight: '60vh',
    summaryImageWidth: '23vw',
    role: 'Full-stack developer',
    viewProjectbackgroundColor: 'rgb(222, 162, 222))',
    viewProjectTextColor: 'blsck',
    architecture: everest_architecture,
  },

  // {
  //   id: 4,
  //   urlExtension: 'schoolx',
  //   title: 'SCHOOLX',
  //   projectName: 'Project SchoolX',
  //   projectDescription: 'A database management system for junior high schools',
  //   projectImage: schoolx2,
  //   projectYear: 2021,
  //   projectGitHub: 'https://github.com/coderacheal/schoolx',
  //   projectWebsite: 'https://github.com/coderacheal/schoolx',
  //   miniIntro: 'Built to help local schools in my home district, SchoolX is a desktop application that helps schools to track their income and revenue and visualize their data.',
  //   projectSummaryOne: 'SchoolX is a project that enables junior high schools in poor districts that cannot afford their own management sytems a way to manage a bulk of their administrative task in student registration, fees logging, staffing and expenditure tracking. It is light weight desktop application, that makes school management seamless',
  //   projectSummaryTwo: ' Tools include Python, SQL, and KivyMD for material design',
  //   builtFor: 'A management app for schools',
  //   carouselImages: [everest1, sage2],
  //   backgroundImage: sage2,
  //   // firstCarouselImage: ,
  //   // secondCarouselImage: ,
  //   // thirdCarouselImage: ,
  //   nextProjectName: 'Project Sunday',
  //   backgroundColor: 'yellow',
  //   viewProject: 'View Next Project',
  //   route: '/sunday',
  //   techStack: ['JAVASCRIPT', 'PYTHON', 'TensorFlow.js', 'Pytorch'],
  //   color: 'white',
  //   summaryImage: schoolx2,
  //   projectCardImage: schoolx2,
  //   summaryImageHeight: '50vh',
  //   summaryImageWidth: '35vw',
  //   viewProjectbackgroundColor: 'rgb(223, 182, 255)',
  //   viewProjectTextColor: '',
  // },
  

];

export default projectData;

export { PageTransition };
