import React from 'react';
import {
  BrowserRouter as Router, Routes, Route,
} from 'react-router-dom';
import Lenis from '@studio-freight/lenis';
import HomePage from './Home-page/HomePage';
import AboutPage from './Home-page/AboutPage';
import ContactPage from './Home-page/ContactPage';
import ProjectsPage from './Home-page/ProjectsPage';
import Everest from './Indivual-Projects/Everest';
import Insight from './Indivual-Projects/Insight';
import Sunday from './Indivual-Projects/Sunday';


const App = () => {

  const lenis = new Lenis();

  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);

  }
  
  
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  requestAnimationFrame(raf);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/work" element={<ProjectsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/insight" element={<Insight />} />
        <Route path="/everest" element={<Everest />} />
        <Route path="/sunday" element={<Sunday />} />
      </Routes>
    </Router>
  );
};

export default App;



